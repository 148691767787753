function initStoriesLinks() {
    var story;
    var hash = window.location.hash;

    if (hash) {
        hash = hash.substr(1, hash.length);

        if ($('.story-links[data-slug="' + hash + '"]').length) {
            story = $('.story-links[data-slug="' + hash + '"]');
        } else if ($('.story-links[data-story="' + hash + '"]').length) {
            story = $('.story-links[data-story="' + hash + '"]');
        }

        if(story) {
            $('.links-wrapper').animate({
                scrollTop: story.offset().top - 75
            },300);
        }
    }
}

function storyLinks(data) {
    var links = '';
    $.each(data, function (index, link) {
        links += `<li><a href="${link.link}" target="_BLANK"><div class="icon icon-arrow"></div> <span>${link.label}</span></a></li>`;
    });

    return links;
}

function setupStoriesLinks(data) {
    $('.stories-links').html('');
    $.each(data, function (index, story) {
        var element = $('<div>', {
            class: 'story-links',
            'data-story': story.story,
            'data-slug': story.slug,
            html: `<div class="story-date">
                ${story.day ? `<div class="day">${story.day}</div>` : ''}
                ${story.month ? `<div class="month">${story.month}</div>` : ''}
                ${story.year ? `<div class="year">${story.year}</div>` : ''}
            </div>
            
            ${story.title ? `<h2>${story.title}</h2>` : ''}
            ${story.description ? `<p>${story.description}</p>` : ''}

            ${story.links.length ? `<ul class="links">
                ${storyLinks(story.links)}
            </ul>` : ''}

            <div class="view-story">
                <a href="/#${story.slug ? story.slug : story.story}">EXPERIENCE THIS DAY <div class="icon icon-outline-circle-arrow"></div></a>
            </div>`
        });

        // add it to the stories
        $('.stories-links').append(element);
    });

    if ($('.stories-links').length) {
        initStoriesLinks();
    }
}

if ($('.stories-links').length) {
    $.ajax({
        type: 'POST',
        // url: 'http://jemstories.test/api/tishrei-in-the-moment',
        // url: 'https://jemstories.cursorblue.com/api/tishrei-in-the-moment',
        url: 'https://cms.tishreiinthemoment.com/api/tishrei-in-the-moment',
        data: {
            '_method': 'GET',
            'api_key': 'ea6dd33051285c8c037f75333cb5ebac7cbe868aeca58aab4d57947b59408091'
        },
        dataType: 'json',
        success: function (data) {
            if (data.project) {
                $('[data-input="project_notification_heading"]').html(data.project.notification_heading);
                $('[data-input="project_notification_message"]').html(data.project.notification_message);
                $('[data-input="project_notification_link"]').attr('href', data.project.notification_link);
                $('[data-input="project_sponsor_message"]').html(data.project.sponsor_message);
            }

            setupStoriesLinks(data.stories);
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });
}

$('body').on('click', '.show-notifications', function() {
    $('.notifications').addClass('visible');
    
    return false;
});
$('body').on('click', '.cancel-notifications', function () {
    var $this = $(this);
    if($this.attr('href')) {
        window.location.href = $this.attr('href');
        return false;
    }
    $('.notifications').removeClass('visible');
    return false;
});
