function closeDropdown(exclude) {

    var element;

    // menus
    if ($('.menu-wrapper.opened').length) {
        $('.menu-wrapper.opened').each(function () {
            element = $(this);
            if (exclude !== element.attr('data-dropdown')) {
                element.removeClass('opened');
            }
        });
    }
}

// if clicked out of the dropdown, close the dropdown
// var dropdownDragging = false;

// $('body').on('touchstart', function() {
//     dropdownDragging = false;
// });

// $('body').on('touchmove', function() {
//     dropdownDragging = true;
// });
// $('body').on('click touchend', function(e) {
//     if(dropdownDragging) {
//         return false;
//     }

//     e.stopPropagation();
//     closeDropdown($(e.target).attr('data-dropdown'));
// });

$('body').on('click', function (e) {
    e.stopPropagation();
    closeDropdown($(e.target).attr('data-dropdown'));
});