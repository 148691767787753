/* globals TimelineMax, TweenMax, Power0 */

function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof window.DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}


// Config
var config = {
    linksPage: 'links.html',
    playMode: 'last',
    slidePlayMode: 'first',
    saveLocation: true
};


// setup the stories
var stories = {
    nextStoryTimeline: null,
    previousStoryTimeline: null,
    animating: false,
    moving: false,
    start: null,
    direction: null,
    width: 0,
    left: 0,
    percent: 0,
    seek: 0
};


// setup the timeline
var slideProgress;
var story;
var slide;
var slideProgressTimeline;

function setInitialStorySlide() {
    var hash = window.location.hash;
    var slide;

    if(hash) {
        hash = hash.substr(1, hash.length);
        hash = hash.split('/');

        if ($('.story[data-slug="' + hash[0] + '"]').length) {
            story = $('.story[data-slug="' + hash[0] + '"]');
        } else if ($('.story[data-story="' + hash[0] + '"]').length) {
            story = $('.story[data-story="' + hash[0] + '"]');
        }

        if(story) {
            story.addClass('active');

            if(hash[1]) {
                if ($('.slide[data-slug="' + hash[1] + '"]', story).length) {
                    slide = $('.slide[data-slug="' + hash[1] + '"]', story);
                } else if ($('.slide[data-slide="' + hash[1] + '"]', story).length) {
                    slide = $('.slide[data-slide="' + hash[1] + '"]', story);
                }

                if(slide) {
                    $('.slide-progress', story).slice(0, slide.index()).addClass('completed');
                    TweenMax.set($('.elapsed', $('.slide-progress', story).slice(0, slide.index())), {
                        width: '100%'
                    });

                    slideProgress = $('.slide-progress', story).eq(slide.index());
                    return;
                }
            }
            
            
            slideProgress = $('.slide-progress', story).first();
            return;
        }
    }

    // if we have a current story saved, get the story
    if (config.saveLocation && localStorage.getItem('story') && $('.story[data-story="' + localStorage.getItem('story') + '"]').length) {
        console.log('active story', localStorage.getItem('story'));
        
        story = $('.story[data-story="' + localStorage.getItem('story') + '"]');
        story.addClass('active');
        
        // if we have a current slide saved get the slide
        if (localStorage.getItem('slide') && $('.slide[data-slide="' + localStorage.getItem('slide')+'"]', story).length) {
            // update all the < slide progress to 100%
            slide = $('.slide[data-slide="' + localStorage.getItem('slide') + '"]', story);

            $('.slide-progress', story).slice(0, slide.index()).addClass('completed');
            TweenMax.set($('.elapsed', $('.slide-progress', story).slice(0, slide.index())), {
                width: '100%'
            });

            // set the slide progress
            slideProgress = $('.slide-progress', story).eq($('.slide[data-slide="' + localStorage.getItem('slide') + '"]', story).index());
            return;
        }

        slideProgress = $('.slide-progress', story).first();
        return;
    }

    switch (config.playMode) {
        case 'first':
            story = $('.story').first();
            break;
        case 'last':
        default:
            story = $('.story').last();
            break;
    }
    
    story.addClass('active');

    switch (config.slidePlayMode) {
        case 'last':
            slideProgress = $('.slide-progress', story).last();
            break;

        case 'first':
        default:
            slideProgress = $('.slide-progress', story).first();
            break;
    }
    
}

function initStories() {

    // set which slide should be the deafult one
    setInitialStorySlide();

    // set the active story position
    TweenMax.set($('.story.active'), {
        rotationZ: 0,
        x: '0%',
        y: '0px'
    });

    // set the next class
    if ($('.story.active').next().length) {
        $('.story.active').next().addClass('next');
    }

    // set the previous class
    if ($('.story.active').prev().length) {
        $('.story.active').prev().addClass('previous');
    }

    // set the previous position
    if ($('.story.previous').length) {
        TweenMax.set($('.story.previous'), {
            rotationZ: -30,
            x: '-100%',
            y: '-100px'
        });
    }

    // start the fisrt slide
    play();
}

function storySlideProgress(data) {
    var progress = '';

    for (let index = 0; index < data.length; index++) {
        progress += '<div class="slide-progress"><div class="elapsed"></div></div>'
    }

    return progress;
}

function slideFooter(slide) {

    if(!slide.description && !slide.links.length) {
        return '';
    }

    var links = '';
    $.each(slide.links, function(index, link) {
        links += `<li><a href="${link.link}" target="_BLANK"><div class="icon icon-arrow"></div> <span>${link.label}</span></a></li>`;
    });

    return `<footer>
        ${slide.links.length ? `<nav>
            <ul>
                ${links}
            </ul>
        </nav>` : ''}
        

        ${slide.description ? `<div class="text">
            <p>${slide.description}</p>
        </div>` : ''}
    </footer>`;
}

function storySlides(data) {
    var slides = '';
    $.each(data, function(index, slide) {
        slides += `<div data-duration="${slide.duration ? slide.duration : 7}" data-slide="${slide.slide}" data-slug="${slide.slug}" data-short_url="${slide.short_url}" data-title="${slide.title ? slide.title : ''}" data-social='${JSON.stringify(slide.social)}' class="slide ${slide.template}">
            <a href="" class="slide-previous"></a>
            <a href="" class="slide-next"></a>
            
            ${slide.asset_type == 'video' ? `<a href="" class="video-sound icon-sound-on"></a>` : ''}

            ${slide.asset_type == 'image' ? `<figure><img src="${slide.asset_url}" alt="${slide.title}"></figure>` : ''}
            ${slide.asset_type == 'video' ? `<div class="video-wrapper">
                <video playsinline webkit-playsinline
                    preload="metadata" data-src="${slide.asset_url}">
                    <source src="${slide.asset_url}" type="video/mp4">
                </video>
            </div>` : ''}

            ${slideFooter(slide)}
        </div>`;
    });
    return slides;
}

function setupStories(data) {
    $('.stories').html('');
    $.each(data, function(index, story) {
        var element = $('<div>', {
            class: 'story',
            'data-story': story.story,
            'data-slug': story.slug,
            html: `<header class="story-header">
                <div class="slides-progress-wrapper">
                    ${storySlideProgress(story.slides)}
                </div>

                <nav>
                    <h3 class="story-title">${story.day_title} ${story.title} <span></span></h3>

                    <ul class="menu">
                        <li><a href="" class="share-slide icon-share"></a></li>
                        <li><a href="links.html#${story.slug ? story.slug : story.story}" class="icon-solid-circle-arrow"></a></li>
                    </ul>
                </nav>
            </header>

            <div class="slides">
                ${storySlides(story.slides)}
            </div>`
        });


        // set the story to the right 
        TweenMax.set(element, {
            rotationZ: 30,
            x: '100%',
            y: '-100px'
        });

        // add it to the stories
        $('.stories').append(element);
    });

    if ($('.stories').length) {
        initStories();
    }
}

function setStoriesWidth() {
    if (!$('.stories-wrapper').length) {
        return;
    }
    // width: heigth / 100 * (100 / (16 / 9))
    var width = ($('.stories-wrapper').height() / 100) * 56.25;
    document.documentElement.style.setProperty('--stories-width', width+'px');
}

if($('.stories').length) {
    setStoriesWidth();
    $.ajax({
        type: 'POST',
        // url: 'http://jemstories.test/api/tishrei-in-the-moment',
        // url: 'https://jemstories.cursorblue.com/api/tishrei-in-the-moment',
        url: 'https://cms.tishreiinthemoment.com/api/tishrei-in-the-moment',
        data: {
            '_method': 'GET',
            'api_key': 'ea6dd33051285c8c037f75333cb5ebac7cbe868aeca58aab4d57947b59408091'
        },
        dataType: 'json',
        success: function (data) {
            if(data.project) {
                config.playMode = data.project.play_mode;
                config.slidePlayMode = data.project.slide_play_mode;
                config.saveLocation = data.project.save_location;
            }

            if(data.stories) {
                setupStories(data.stories);
            }
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    $(window).resize(function () {
        setStoriesWidth();
    });
}

$('body').on('touchstart', '.stories, .story', function (e) {
    if (stories.animating) {
        return;
    }

    stories.moving = true;
    stories.width = $('.stories').width();
    stories.start = e.originalEvent.touches[0].clientX - $('.stories').position().left;

    // setup the next animation
    if($('.story.next').length) {
        stories.nextStoryTimeline = new TimelineMax({
            paused: true,
            onStart: function () {
                console.log('next onStart');

                TweenMax.set($('.slide', $('.story.next')).first(), {
                    'autoAlpha': 1
                });

                stories.animating = true;
            },
            onComplete: function() {
                console.log('next onComplete');
                stories.animating = false;
                $('.story.previous').removeClass('previous');
                $('.story.active').removeClass('active').addClass('previous');
                $('.story.next').removeClass('next').addClass('active');

                if ($('.story.active').next().length) {
                    $('.story.active').next().addClass('next');
                }

                // go to the next story
                nextStory();
            },
            onReverseComplete: function () {
                console.log('next onReverseComplete');
                stories.animating = false;
            }
        });
        stories.nextStoryTimeline.to($('.story.active'), 0.3, {
            rotationZ: -30,
            x: '-100%',
            y: '-100px'
        }, 0);
        stories.nextStoryTimeline.to($('.story.next'), 0.3, {
            rotationZ: 0,
            x: '0%',
            y: '0px'
        }, 0);
    }

    // setup the previous animation
    if ($('.story.previous').length) {
        stories.previousStoryTimeline = new TimelineMax({
            paused: true,
            onStart: function() {
                console.log('previous onStart');

                TweenMax.set($('.slide', $('.story.previous')).first(), {
                    'autoAlpha': 1
                });

                TweenMax.set($('.story.previous'), {
                    rotationZ: -30,
                    x: '-100%',
                    y: '-100px'
                });

                stories.animating = true;
            },
            onComplete: function() {
                console.log('previous onComplete');
                stories.animating = false;
                $('.story.next').removeClass('next');
                $('.story.active').removeClass('active').addClass('next');
                $('.story.previous').removeClass('previous').addClass('active');

                if ($('.story.active').prev().length) {
                    $('.story.active').prev().addClass('previous');
                }

                // go to the previous story
                previousStory();
            },
            onReverseComplete: function () {
                console.log('previous onReverseComplete');
                stories.animating = false;
            }
        });
        stories.previousStoryTimeline.to($('.story.active'), 0.3, {
            rotationZ: 30,
            x: '100%',
            y: '-100px'
        }, 0);
        stories.previousStoryTimeline.to($('.story.previous'), 0.3, {
            rotationZ: 0,
            x: '0%',
            y: '0px'
        }, 0);
    }
});
$('body').on('touchmove', '.stories, .story', function (e) {
    console.log('moving', stories.moving, stories.animating);
    if(!stories.moving && !stories.animating) {
        return;
    }

    stories.left = e.originalEvent.touches[0].clientX - $('.stories').position().left;

    // make sure not less then 0
    stories.left = stories.left < 0 ? 0 : stories.left;

    // make sure not more then the amount
    stories.left = stories.left > stories.width ? stories.width : stories.left;

    // calculate the direction
    stories.direction = stories.left >= stories.start ? 'right' : 'left';

    // calculate the percentage
    stories.percent = (Math.abs(stories.left - stories.start) / stories.width) * 100;

    if (stories.direction == 'left' && $('.story.next').length) {
        stories.seek = (stories.nextStoryTimeline.duration()/100)*stories.percent;
        stories.nextStoryTimeline.seek(stories.seek, false);
    }

    if (stories.direction == 'right' && $('.story.previous').length) {
        stories.seek = (stories.previousStoryTimeline.duration() / 100) * stories.percent;
        stories.previousStoryTimeline.seek(stories.seek, false);
    }

    // console.log(stories.direction, stories.percent, stories.seek);
});

$('body').on('touchend', '.stories, .story', function () {
    console.log('moveend', stories.moving, stories.animating);
    if(!stories.moving && !stories.animating) {
        return;
    }

    console.log(stories);

    // if (stories.direction == 'left' && $('.story.next').length) {
    if (stories.nextStoryTimeline && $('.story.next').length) {
        if (stories.nextStoryTimeline.progress() >= 0.15) {
            stories.nextStoryTimeline.play();
        } else {
            stories.nextStoryTimeline.reverse();
        }
    }

    if (stories.previousStoryTimeline && $('.story.previous').length) {
    // if (stories.direction == 'right' && $('.story.previous').length) {
        if (stories.previousStoryTimeline.progress() >= 0.15) {
            stories.previousStoryTimeline.play();
        } else {
            stories.previousStoryTimeline.reverse();
        }
    }

    // reset the stories
    stories.moving = false;
    stories.start = null;
    stories.direction = null;
    stories.width = 0;
    stories.left = 0;
    stories.percent = 0;
    stories.seek = 0;
});

function slideDuration(slide) {
    var duration;

    if(slide.attr('data-duration')) {
        duration = parseInt(slide.attr('data-duration'));
    }

    return duration;
}

// play the slide
function play() {
    slide = $('.slide', story).eq(slideProgress.index());
    localStorage.setItem('slide', slide.attr('data-slide'));
    localStorage.setItem('story', slide.parents('.story').attr('data-story'));
    
    // set the slide title to the story
    $('.story-title span', story).html(slide.attr('data-title'));

    if($('figure', slide).length) {
        slideProgressTimeline = new TimelineMax({
            paused: true,
            onStart: function () {
                TweenMax.set($('.slide', story), {
                    'autoAlpha': 0
                });

                TweenMax.set(slide, {
                    'autoAlpha': 1
                });

                TweenMax.set($('.elapsed', slideProgress), {
                    width: '0%'
                });
            },
            onComplete: function () {
                nextSlide();
            }
        });
    
        var duration = slideDuration(slide);
    
        slideProgressTimeline.to($('.elapsed', slideProgress), duration, {
            width: '100%',
            ease: Power0.easeNone
        });
    
        slideProgressTimeline.play();
        return;
    }

    if($('.video-wrapper', slide).length) {
        TweenMax.set(slide, {
            'autoAlpha': 1
        });

        var video = $('video', slide)[0];

        var promise = video.play();
        if (promise !== undefined) {
            promise.then(function () {
                video.play();
                $('.video-controls', slide).attr('data-mode', 'playing');

                // if the video is muted
                if(video.muted) {
                    $('.video-sound', slide).addClass('visible');
                }
                
            }).catch(function (error) {
                console.log(error, slide.index(), 'video playing failed');
                console.log('show the ui');

                // loop through all the videos and mute them
                $('.video-wrapper video').each(function () {
                    var video = $(this)[0];
                    video.muted = true;
                });

                // video.muted = true;
                video.play();
                $('.video-sound', slide).addClass('visible');
            });
        }

        $('video', slide).on('timeupdate', function () {
            // console.log(this.duration, this.currentTime, percent);
            var percent = this.currentTime / this.duration * 100;
            $('.elapsed', slideProgress).css('width', percent + '%');
            if (this.currentTime >= this.duration) {
                nextSlide();
            }
        });
    }
}

function nextStory() {
    if (slideProgressTimeline) slideProgressTimeline.kill();
    $('.elapsed', slideProgress).addClass('completed');
    TweenMax.set($('.elapsed', slideProgress), {
        width: '100%'
    });

    if ($('.video-wrapper', slide).length) {
        var video = $('video', slide)[0];
        video.pause();
    }

    story = story.next();
    // get the first unplayed slide on the next story
    if ($('.slide-progress', story).not('.completed').length) {
        TweenMax.set($('.elapsed', $('.slide-progress.completed', story)), {
            width: '100%'
        });

        slideProgress = $('.slide-progress', story).not('.completed').first();

        play();
        return;
    }

    // reset all to unplayed and play the first slide
    $('.slide-progress', story).removeClass('completed');
    TweenMax.set($('.elapsed', story), {
        width: '0%'
    });

    slideProgress = $('.slide-progress', story).first();
    play();
}

function previousStory() {
    if (slideProgressTimeline) slideProgressTimeline.kill();
    $('.elapsed', slideProgress).addClass('completed');
    TweenMax.set($('.elapsed', slideProgress), {
        width: '100%'
    });

    if ($('.video-wrapper', slide).length) {
        var video = $('video', slide)[0];
        video.pause();
    }

    story = story.prev();
    // get the first unplayed slide on the next story
    if ($('.slide-progress', story).not('.completed').length) {
        TweenMax.set($('.elapsed', $('.slide-progress.completed', story)), {
            width: '100%'
        });

        slideProgress = $('.slide-progress', story).not('.completed').first();

        play();
        return;
    }

    // reset all to unplayed and play the first slide
    $('.slide-progress', story).removeClass('completed');
    TweenMax.set($('.elapsed', story), {
        width: '0%'
    });

    slideProgress = $('.slide-progress', story).first();
    play();
}

// go to the next slide
function nextSlide() {

    // update the slide progress to completed
    slideProgress.addClass('completed');

    if (!slideProgress.next().length) {
        if(story.next().length) {
            stories.nextStoryTimeline = new TimelineMax({
                paused: true,
                onStart: function () {
                    console.log('next onStart');

                    TweenMax.set($('.slide', $('.story.next')).first(), {
                        'autoAlpha': 1
                    });

                    stories.animating = true;
                },
                onComplete: function () {
                    console.log('next onComplete');
                    stories.animating = false;
                    $('.story.previous').removeClass('previous');
                    $('.story.active').removeClass('active').addClass('previous');
                    $('.story.next').removeClass('next').addClass('active');

                    if ($('.story.active').next().length) {
                        $('.story.active').next().addClass('next');
                    }

                    // go to the next story
                    nextStory();
                },
                onReverseComplete: function () {
                    console.log('next onReverseComplete');
                    stories.animating = false;
                }
            });
            stories.nextStoryTimeline.to($('.story.active'), 0.3, {
                rotationZ: -30,
                x: '-100%',
                y: '-100px'
            }, 0);
            stories.nextStoryTimeline.to($('.story.next'), 0.3, {
                rotationZ: 0,
                x: '0%',
                y: '0px'
            }, 0);

            stories.nextStoryTimeline.play();
            return false;
        }

        window.location.href = config.linksPage;
        
        return false;
    }

    if ($('.video-wrapper', slide).length) {
        var video = $('video', slide)[0];
        video.pause();
        video.currentTime = 0;
    }

    slideProgress = slideProgress.next();

    TweenMax.set(slide, {
        'autoAlpha': 0
    });
    play();
}

function previousSlide() {
    // remove the completed
    slideProgress.removeClass('completed');

    if (!slideProgress.prev().length) {
        if (story.prev().length) {
            stories.previousStoryTimeline = new TimelineMax({
                paused: true,
                onStart: function () {
                    console.log('previous onStart');

                    TweenMax.set($('.slide', $('.story.previous')).first(), {
                        'autoAlpha': 1
                    });

                    TweenMax.set($('.story.previous'), {
                        rotationZ: -30,
                        x: '-100%',
                        y: '-100px'
                    });

                    stories.animating = true;
                },
                onComplete: function () {
                    console.log('previous onComplete');
                    stories.animating = false;
                    $('.story.next').removeClass('next');
                    $('.story.active').removeClass('active').addClass('next');
                    $('.story.previous').removeClass('previous').addClass('active');

                    if ($('.story.active').prev().length) {
                        $('.story.active').prev().addClass('previous');
                    }

                    // go to the previous story
                    previousStory();
                },
                onReverseComplete: function () {
                    console.log('previous onReverseComplete');
                    stories.animating = false;
                }
            });
            stories.previousStoryTimeline.to($('.story.active'), 0.3, {
                rotationZ: 30,
                x: '100%',
                y: '-100px'
            }, 0);
            stories.previousStoryTimeline.to($('.story.previous'), 0.3, {
                rotationZ: 0,
                x: '0%',
                y: '0px'
            }, 0);

            stories.previousStoryTimeline.play();
            return false;
        }
        return false;
    }

    if ($('.video-wrapper', slide).length) {
        var video = $('video', slide)[0];
        video.pause();
        video.currentTime = 0;
    }

    slideProgress = slideProgress.prev();
    TweenMax.set($('.elapsed', slideProgress), {
        width: '0%'
    });

    TweenMax.set(slide, {
        'autoAlpha': 0
    });
    play();
}

var longClick = false;
var startClick, endClick;

// slide navigation
$('body').on('click', '.slide-next', function (e) {
    console.log('clicked');
    if(longClick) {
        e.preventDefault();
        return false;
    }

    if(slideProgressTimeline) slideProgressTimeline.kill();
    TweenMax.set($('.elapsed', slideProgress), {
        width: '100%'
    });
    nextSlide();
    return false;
});
$('body').on('click', '.slide-previous', function (e) {
    if (longClick) {
        e.preventDefault();
        return false;
    }

    if(slideProgressTimeline) slideProgressTimeline.kill();
    TweenMax.set($('.elapsed', slideProgress), {
        width: '0%'
    });
    previousSlide();
    return false;
});

// stroy navigation
$('body').on('click', '.story-next', function() {
    if (slideProgressTimeline) slideProgressTimeline.kill();
    TweenMax.set($('.elapsed', slideProgress), {
        width: '100%'
    });
    nextSlide();

    // if (!story.next().length) {
    //     return false;
    // }

    // stories.nextStoryTimeline = new TimelineMax({
    //     paused: true,
    //     onStart: function () {
    //         console.log('next onStart');

    //         TweenMax.set($('.slide', $('.story.next')).first(), {
    //             'autoAlpha': 1
    //         });

    //         stories.animating = true;
    //     },
    //     onComplete: function () {
    //         console.log('next onComplete');
    //         stories.animating = false;
    //         $('.story.previous').removeClass('previous');
    //         $('.story.active').removeClass('active').addClass('previous');
    //         $('.story.next').removeClass('next').addClass('active');

    //         if ($('.story.active').next().length) {
    //             $('.story.active').next().addClass('next');
    //         }

    //         // go to the next story
    //         nextStory();
    //     },
    //     onReverseComplete: function () {
    //         console.log('next onReverseComplete');
    //         stories.animating = false;
    //     }
    // });
    // stories.nextStoryTimeline.to($('.story.active'), 0.3, {
    //     rotationZ: -30,
    //     x: '-100%',
    //     y: '-100px'
    // }, 0);
    // stories.nextStoryTimeline.to($('.story.next'), 0.3, {
    //     rotationZ: 0,
    //     x: '0%',
    //     y: '0px'
    // }, 0);

    // stories.nextStoryTimeline.play();
    return false;
});

$('body').on('click', '.story-previous', function () {
    if (slideProgressTimeline) slideProgressTimeline.kill();
    TweenMax.set($('.elapsed', slideProgress), {
        width: '0%'
    });
    previousSlide();

    // if (!story.prev().length) {
    //     return false;
    // }

    // stories.previousStoryTimeline = new TimelineMax({
    //     paused: true,
    //     onStart: function () {
    //         console.log('previous onStart');

    //         TweenMax.set($('.slide', $('.story.previous')).first(), {
    //             'autoAlpha': 1
    //         });

    //         TweenMax.set($('.story.previous'), {
    //             rotationZ: -30,
    //             x: '-100%',
    //             y: '-100px'
    //         });

    //         stories.animating = true;
    //     },
    //     onComplete: function () {
    //         console.log('previous onComplete');
    //         stories.animating = false;
    //         $('.story.next').removeClass('next');
    //         $('.story.active').removeClass('active').addClass('next');
    //         $('.story.previous').removeClass('previous').addClass('active');

    //         if ($('.story.active').prev().length) {
    //             $('.story.active').prev().addClass('previous');
    //         }

    //         // go to the previous story
    //         previousStory();
    //     },
    //     onReverseComplete: function () {
    //         console.log('previous onReverseComplete');
    //         stories.animating = false;
    //     }
    // });
    // stories.previousStoryTimeline.to($('.story.active'), 0.3, {
    //     rotationZ: 30,
    //     x: '100%',
    //     y: '-100px'
    // }, 0);
    // stories.previousStoryTimeline.to($('.story.previous'), 0.3, {
    //     rotationZ: 0,
    //     x: '0%',
    //     y: '0px'
    // }, 0);

    // stories.previousStoryTimeline.play();
    return false;
});

// context menu
window.oncontextmenu = function (e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
};

// slide pausing start
$('body').on(isTouchDevice() ? 'touchstart' : 'mousedown', '.slide-next, .slide-previous', function () {
    // console.log('mousedown touchstart', e, e.type);

    // if (e.type == 'touchstart') {
    //     e.stopPropagation();
    //     e.preventDefault();
    // }

    startClick = new Date().getTime();

    if ($('figure', slide).length) {
        slideProgressTimeline.pause();
        return;
    }

    if ($('.video-wrapper', slide).length) {
        var video = $('video', slide)[0];
        video.pause();
        return;
    }
});


// slide pausing end
$('body').on(isTouchDevice() ? 'touchend' : 'mouseup', '.slide-next, .slide-previous', function () {
    endClick = new Date().getTime();
    // console.log(startClick, endClick);

    if((endClick - startClick) < 300) {
        longClick = false;
        // console.log('< 300');
    } else {
        longClick = true;
        // console.log('>= 300');

        if ($('figure', slide).length) {
            slideProgressTimeline.play();
            return;
        }

        if ($('.video-wrapper', slide).length) {
            var video = $('video', slide)[0];
            video.play();
            return;
        }
    }
    // return false;
});

// share
$('body').on('click', '.share-slide', function() {
    var hash = '#';
    var url = '#';
    
    if (slide.attr('data-short_url')) {
        url = slide.attr('data-short_url');
    } else {
        // add the story
        if (story.attr('data-slug') && story.attr('data-slug') != 'null') {
            hash += story.attr('data-slug');
        } else {
            hash += story.attr('data-story');
        }

        // add the slide
        if (slide.attr('data-slug') && slide.attr('data-slug') != 'null') {
            hash += '/'+slide.attr('data-slug');
        } else {
            hash += '/'+slide.attr('data-slide');
        }

        url = window.location.origin + window.location.pathname + hash;
    }

    if(navigator.share) {
        navigator.share({
            // title: $('.story-title', story).text(),
            // text: $('.story-title', story).text(),
            url: url
        }).then(() => {
            console.log('thanks for shareing');
        }).catch(console.error);

        closeDropdown();
        return false;
    }

    // console.log('open share menu');
    $('.share-story .link').html(url);
    var social = JSON.parse(slide.attr('data-social'));

    $('.share-story .whatsapp').attr('href', social.whatsapp);
    $('.share-story .email').attr('href', social.email);
    $('.share-story .sms').attr('href', social.sms);
    $('.share-story .facebook').attr('href', social.facebook);
    $('.share-story .twitter').attr('href', social.twitter);

    $('.share-story').addClass('visible');

    closeDropdown();
    return false;
});

$('body').on('click', '.close-share-story', function() {
    $('.share-story').removeClass('visible');
    
    return false;
});

// turn on sound
$('body').on('click', '.video-sound', function() {
    var $this = $(this);
    $this.removeClass('visible');
    
    // loop through all the videos and mute them
    $('.video-wrapper video').each(function () {
        var video = $(this)[0];
        video.muted = false;
    });
    
    // var slide = $this.parents('.slide');
    // if ($('.video-wrapper', slide).length) {
    //     var video = $('video', slide)[0];
    //     video.muted = false;
    // }
    return false;
});


// pausing when user leaves
document.addEventListener("visibilitychange", function () {
    var video;
    if (document.visibilityState === 'visible') {

        if ($('.video-wrapper', slide).length) {
            video = $('video', slide)[0];
            video.play();
        }

    } else {

        if ($('.video-wrapper', slide).length) {
            video = $('video', slide)[0];
            video.pause();
        }

    }
});