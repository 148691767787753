jQuery.fn.selectText = function () {
    var doc = document;
    var element = this[0];
    var range, selection;
    // console.log(this, element);
    if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};


$('body').on('click', '.open-menu', function () {
    var $this = $(this);
    var menu = $this.parents('.menu-wrapper');

    if (menu.hasClass('opened')) {
        closeDropdown();
        return false;
    }

    menu.addClass('opened');
    closeDropdown($this.parents('.menu-wrapper').attr('data-dropdown'));
    return false;
});

$('body').on('click', '.copy', function () {
    var $this = $(this);
    var element = $('<div>', {
        html: $this.siblings('.link').html()
    });
    $('body').append(element);

    // $this.siblings('.link').selectText();
    element.selectText();
    document.execCommand("Copy", false, null);
    window.getSelection().removeAllRanges();
    element.remove();

    $this.addClass('showing');
    setTimeout(() => {
        $this.removeClass('showing');
    }, 1500);
    return false;
});